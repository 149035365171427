import React from "react"
import 'css/SlideList.css';
import buttonNext from 'gfx/button_left_dark_white.png';
import buttonPrevious from 'gfx/button_right_dark_white.png';


export default class Slide extends React.Component {

	constructor() {
		super();

		this.state = {

		}


	}



	componentDidMount() {

	}

	componentWillUnmount() {

	}



	render() {

		const slide = this.props.slide;

		let css = {};

		if (slide.css.backgroundImage) {
			css.backgroundImage = slide.css.backgroundImage
		}

		if (slide.css.backgroundSize) {
			css.backgroundSize = slide.css.backgroundSize
		}


		const contentDesktop = <div className="contentDesktop">
			<h1>{slide.title}</h1>
			<p>
				bla bla bla bla sfsd fsdfsdf sd<br /><br />sdffsdfsdfsdbla bla bla bla sfsd fsdfsdf sd<br /><br />sdffsdfsdfsdbla bla bla bla sfsd
        fsdfssdfsdfsdbla bla bla bla sfsd fsdf sdfsdf DONE
      </p>
			<div id="readMore">Läs mer</div>
		</div>

		const contentMobile = <div className="contentMobile">

			{slide.image1 && slide.image1 != "" ? <img src={slide.image1} /> : ''}
			<p>
				bla bla bla bla sfsd fsdfsdf sd<br /><br />sdffsdfsdfsdbla bla bla bla sfsd fsdfsdf sd<br /><br />sdffsdfsdfsdbla bla bla bla sfsd
        fsdfssdfsdfsdbla bla bla bla sfsd fsdf sdfsdf DONE
      </p>

		</div>

		let desktop = null;
		let mobile = <div id="mobile"><h1>MOBILE {slide.title}</h1>{contentMobile}<div id="readMore">Läs mer</div></div>


		if (slide.image1 && slide.image1 != "") {

			desktop = <div id="desktop" className="image"><div>{contentDesktop}</div><div><img src={slide.image1} /></div></div>




		} else {
			desktop = <div id="desktop">{contentDesktop} </div>
		}

		return (



			<div className="slide" style={css} title={JSON.stringify(slide)}>

				{desktop}
				{mobile}


			</div>
		)


	}
}